
/* eslint-disable */

import { ref, reactive, onMounted, watch } from "vue";
import { useRouter } from 'vue-router'
import { helper as $h } from "@/utils/helper"
import CloudFun, { Condition, Operator, defineComponent } from "@cloudfun/core";
import FullCalendar, { Calendar } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";

import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { OrderQueryData } from "@/views/order/types";

export default defineComponent({
  components: {
    FullCalendar
  },
  props: {
      modelValue: Boolean,
      data: { type: Object, default: {} },
  },
  setup(props, {emit, attrs}) {

      const model = CloudFun.current?.model,
      $t = CloudFun.current?.i18n.global.t,
      queryData = reactive<OrderQueryData>({
        store: "",
        employee: "",
        startDate: "",
        endDate: "",
      });
    const monthCalRef = ref<Calendar>(),
          monthCalendarApi = ref();

    const currentDate: any = ref(""),
      selectedStylist = ref();
    const title = ref("");
    const monthOptions: any = reactive({
      eventDurationEditable: true,
      dayMaxEvents: 3,
      dayMaxEventRows: 3,
      allDaySlot: false,
      customButtons: {
        dateButton: {
          text: "日曆",
          // icon: 'icon-calendar',
        },
      },
      plugins: [
        dayGridPlugin,
        interactionPlugin
      ],
      resourceAreaWidth: 100,
      headerToolbar: {
        left: "",
        center: "title",
        right: "prev,next",
      },
      buttonText: {
        today: "今日",
        week: "週",
        day: "日",
        month: "月",
      },
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: false,
        hour12: false,
      },
      locale: "zh-tw",
      initialView: "dayGridMonth",
      // initialDate: self.$route.params.date,
      events: [],
      // resources: [],
      // resourceOrder: "index,order",
      slotDuration: "00:15:00",
      slotMinTime: "00:00",
      slotMaxTime: "23:59",
      businessHours: [],
      selectable: true,
      selectConstraint: "businessHours",
      // slotMinWidth: 40,
      // contentHeight: 600,
      height: "auto",
      droppable: true,
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
      eventContent: function (arg) {
        // console.log("eventContent:", arg.event);
        if (arg.event) {
          let event = arg.event;
          var icon = event.extendedProps.icon,
            // start = self.$cf.dateExt.formatDate(event.start, "HH:mm"),
            title = event.title,
            isNew = event.extendedProps.isNew,
            content = event.extendedProps.content
              ? event.extendedProps.content
              : "",
            eventHTML = `<i class="mr-1 ${icon}"></i>${title}<br>${content}`;
          if (isNew) {
            eventHTML = '<mark class="newCustomer">N</mark>' + eventHTML;
          }
          return {
            html: eventHTML,
          };
        }
      },
      eventClassNames: function (arg) {
        return arg.event.extendedProps.class;
      },
      datesSet: function (info) {
        // if (self.monthMode) {
        if (currentDate.value !== info.startStr) {
          console.log("Date change", info);
          currentDate.value = info.startStr;
          queryData.startDate = $h.formatDate(new Date(info.startStr), "yyyy/MM/dd");
          queryData.endDate = $h.formatDate(new Date(info.endStr), "yyyy/MM/dd");

          refreshMonthCal();
        } else {
          return;
        }
        // }
      },
      dayCellDidMount: function (arg) {
        // console.log(arg);
        if (arg.view.type !== "dayGridMonth") {
          arg.el.style.backgroundColor = arg.resource.extendedProps.color;
        }
      },
      dateClick: function (info) {
        if (info.view.type === "dayGridMonth") {
            emit("gotoDate", info.date);
            hideModal();
          }
          
      },


    });

    onMounted(() => {
      if (monthCalRef.value) {
        console.log("calendarRef:", monthCalRef.value);
        // @ts-ignore
        monthCalendarApi.value = monthCalRef.value.getApi();
      }
    })

    watch(
      () => props.data,
      async (value) => {
        queryData.employee = value.Id;
        title.value = `${value.Name}的月班表(點擊日期跳轉至當週/日的班表)`;
        refreshMonthCal();
        setTimeout(function () {
          updateModalSize();
        }, 1000);
      },
      { deep: true }
    );
    
    const updateModalSize = () => {
      monthCalendarApi.value.updateSize();
    }

    const hideModal = () => {
      emit("update:modelValue", false);
    };

    const refreshMonthCal = () => {
      const calendarEle = monthOptions,
        condition = new Condition();
      // if (queryData.store)
      //   condition.and("StoreId", Operator.Equal, queryData.store);
      if (queryData.employee)
        condition.and("EmployeeId", Operator.Equal, queryData.employee);
      if (queryData.startDate)
        condition.and("StartTime", Operator.GreaterThanOrEqual, queryData.startDate);
      if (queryData.endDate)
        condition.and("EndTime", Operator.LessThanOrEqual, queryData.endDate);


      model?.dispatch("employeeEvent/query", {
        page: 1,
        pageSize: 100,
        condition: condition,
        sortings: { column: "CreatedTime", order: 1 }
      }).then((value) => {
        let reservations = value.data;

        console.log('reservations', reservations);
        reservations = reservations.map((e, index) => {
          let event = JSON.parse(e.SerializeContent.Content)
          if (e.Type === 0) {
            return {
              // eventId: e.Id,
              start: event.StartTime,
              end: event.EndTime,
              title: event.Customer.Name,
              isNew: event.Customer.IsNew,
              stylist: event.Name,
              sex: event.Customer.Gender || "",
              icon: "order-event" + event.Customer.Gender
                ? "man"
                : "woman",
              content: event.Description,
              class: [
                event.Status === 1
                  ? "status-approved"
                  : event.Status === 3
                    ? "status-inprogress"
                    : event.Status === 31
                      ? "status-checkedOut"
                      : "",
                event.EstimatedTime ? "has-estimated" : "",
              ],
              backgroundColor: event.Customer.GradeColor ? event.Customer.GradeColor : "",
              resourceId: event.EmployeeId,
              eventType: event.EventType,
              stylistId: event.Id,
              employeeId: event.EmployeeId,
              customerId: event.CustomerId,
              orderId: e.OrderId,
              editable: event.Status !== 31 ? true : false,
              resourceEditable: event.Status !== 31 ? true : false,
            };
            /* Official holiday */
          } else {
            return {
              start: e.StartTime,
              end: e.EndTime,
              title: e.Description ?? "休假",
              stylist: "",
              icon: "iconsminds-close",
              // content: e.Description,
              class: "status-notAllowed",
              display: "background",
              style: "",
              resourceId: e.EmployeeId,
              EventType: e.EventType,
              eventId: e.EventId,
              orderId: e.OrderId,
              editable: e.Status !== 31 ? true : false,
              resourceEditable: e.Status !== 31 ? true : false,
            };
          }
        })
          calendarEle.events = reservations;
      });
    }
    

    return {
      hideModal,
      monthOptions,
      monthCalRef,
      title
    }
  }
})
