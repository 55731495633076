import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_calendar = _resolveComponent("full-calendar")!
  const _component_vxe_modal = _resolveComponent("vxe-modal")!

  return (_openBlock(), _createBlock(_component_vxe_modal, {
    modelValue: _ctx.modelValue,
    title: _ctx.title,
    width: "922",
    height: "600",
    onClose: _ctx.hideModal,
    zIndex: 1035
  }, {
    default: _withCtx(() => [
      _createVNode(_component_full_calendar, {
        id: "monthly",
        ref: "monthCalRef",
        options: _ctx.monthOptions
      }, null, 8, ["options"])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onClose"]))
}