
/* eslint-disable */

import { ref, reactive, onMounted, onBeforeMount, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import CloudFun, {
  Condition,
  Operator,
  Sorting,
  defineComponent,
} from "@cloudfun/core";
import { OrderQueryData } from "@/views/order/types";

import Swal from "sweetalert2";
import FullCalendar, { Calendar, EventApi } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

import AppointmentModal from "./calendar-modal/AppointmentModal.vue";
import DetailModal from "../../order/main/Detail.vue";
import EmployeePerformanceModal from "./calendar-modal/EmployeePerformanceModal.vue";
import ChangeTimeModal from "./calendar-modal/ChangeTimeModal.vue";
import InsertCustomerModal from "./calendar-modal/InsertCustomerModal.vue";
import LeaveTakenModal from "./calendar-modal/LeaveTakenModal.vue";
import MonthlyViewModal from "./calendar-modal/MonthlyViewModal.vue";

export default defineComponent({
  components: {
    AppointmentModal,
    DetailModal,
    EmployeePerformanceModal,
    ChangeTimeModal,
    InsertCustomerModal,
    LeaveTakenModal,
    MonthlyViewModal,
    FullCalendar,
  },
  setup() {
    const model = CloudFun.current?.model,
      $t = CloudFun.current?.i18n.global.t,
      application = CloudFun.current,
      router = useRouter();
    const queryData = reactive<OrderQueryData>({
      store: "",
      employee: "",
      startDate: "",
      endDate: "",
    });

    const today = ref<string>("");
    // Calendar reference
    const calendarRef = ref<Calendar>();
    const calendarApi = ref();
    const dataLoaded = ref(true);

    const storePeformance = reactive({ customer: "0", amount: "0" }),
      selectedStylist: any = ref(null),
      store = reactive({
        Id: "",
        Name: "",
        Phone: "",
        Email: "",
        ContactPerson: "",
        BookingStartTime: "",
        BookingEndTime: "",
        BookingUnitTime: "",
        BookingIntervalTime: "",
        Address: {
          Line: "",
        },
        Week: [],
        RecessPeriod: { Durations: [] },
        BookingStartTimeDP: "",
        BookingEndTimeDP: "",
      });
    let currentDate: any = "",
      stylists: any[] = [],
      stylistIds = null,
      // storeId = this.$store.getters["auth/defaultStoreId"],
      // stylists =[{ Value =-1, Name ="全部", hide =false }],
      // selectedStylist =-1,
      reserveVisible = ref(false),
      currentOrderId = "",
      specialHours = {},
      isReservable = true;

    const allResources: any = ref([]),
      recessDurationEvents: any = [];

    const appointmentModal = reactive({
        title: "預約內容",
        visible: false,
        data: {},
      }),
      detailModal = reactive({
        visible: false,
        orderId: "",
        show(orderId) {
          console.log("Detail", orderId);
          detailModal.orderId = orderId;
          detailModal.visible = true;
        },
      }),
      changeTimeModal = reactive({
        title: "修改預約時間",
        visible: false,
        orderId: "",
        revertCallback: null,
        data: {},
        show({ orderId, startTime, revertCallback }) {
          console.log("Detail", orderId);
          model
          ?.dispatch("order/find", orderId)
          .then(
            (response) => {
              response.data.List = response.list;
              //console.log(response.data)
              let order = response.data;
              if (order.Time ) order.Time = new Date(order.Time);
              if (order.StartTime && startTime) order.StartTime = new Date(startTime);
              if (order.EndTime) order.EndTime = new Date(order.EndTime);
              changeTimeModal.data = order;
              changeTimeModal.visible = true;
              changeTimeModal.revertCallback = revertCallback;
                
            },
            (failure) => {  
              Swal.fire({
                icon: 'error',
                title: $t?.("message.read-order-detail-fail"),
                text: failure,
                timer: 1500,
              })
              
            }
          )
        },
      }),
      employeePerformanceModal = reactive({
        title: "設計師業績",
        visible: false,
        data: {},
      }),
      insertCustomerModal = reactive({
        title: "預約內容",
        visible: false,
        data: {},
      }),
      leaveTakenModal = reactive({
        title: "休假內容",
        orderId: "",
        visible: false,
        data: {},
      }),
      monthlyViewModal = reactive({
        title: "設計師個人月行事曆",
        visible: false,
        data: {},
      }),
      selectedTime: any = ref(""),
      selectedEmployee: any = reactive({});

    const calendarOptions: any = reactive({
      refetchResourcesOnNavigate: false,
      dayMaxEvents: 3,
      dayMaxEventRows: 3,
      allDaySlot: false,
      customButtons: {
        refreshButton: {
          text: "刷新行事曆",
          click: function () {
            refreshCal();
          },
        },
      },
      titleFormat: {
        // will produce something like "Tuesday, September 18, 2018"
        month: "long",
        year: "numeric",
        day: "numeric",
        weekday: "narrow",
      },
      plugins: [
        dayGridPlugin,
        interactionPlugin,
        timeGridPlugin,
        resourceTimeGridPlugin,
        resourceTimelinePlugin,
      ],
      resourceAreaHeaderContent: $t?.("label.stylist"),
      resourceAreaWidth: 100,
      headerToolbar: {
        left: "refreshButton",
        center: "title",
        right: "prev,next",
      },
      buttonText: {
        today: "今日",
        week: "週",
        day: "日",
        month: "月",
      },
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: false,
        hour12: false,
      },
      locale: "zh-tw",
      initialView: "resourceTimeline",
      // initialDate: self.$route.params.date,
      events: [],
      eventMinWidth: 100,
      resources: [],
      resourceOrder: "index,order",
      slotDuration: "00:15:00",
      slotMinTime: "09:00",
      slotMaxTime: "23:00",
      businessHours: [],
      selectable: true,
      // selectConstraint: "businessHours",
      // slotMinWidth: 40,
      // contentHeight: 600,
      height: "auto",
      droppable: false,
      editable: false,
      eventStartEditable: true,
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
      eventContent: function (arg) {
        console.log("eventContent:", arg.event);
        if (arg.event) {
          let event = arg.event,
            icon = event.extendedProps.icon,
            // start = self.$cf.dateExt.formatDate(event.start, "HH:mm"),
            title = event.title,
            isNew = event.extendedProps.isNew,
            isSpecified = event.extendedProps.isSpecified,
            eventType = event.extendedProps.eventType,
            content = event.extendedProps.content ?? "",
            eventHTML = `<i class="mr-1 ${icon}"></i>${title}<br>${content}`;
          if (isNew) {
            eventHTML =
              '<i class="event-icon newCustomer"><span>N</span></i>' +
              eventHTML;
          }
          if (!isSpecified && eventType === 0) {
            eventHTML =
              eventHTML +
              '<i class="event-icon isSpecified"><span>未<span></i>';
          }
          return {
            html: eventHTML,
          };
        }
      },
      eventDrop: function (eventDropInf) {
        console.log(eventDropInf);
        const oldResourceId = eventDropInf.oldResource?.id; // 获取旧的资源 ID
        const newResourceId = eventDropInf.newResource?.id; // 获取新的资源 ID
        if (newResourceId !== null && oldResourceId !== newResourceId) {
          Swal.fire({ text: "不接受使用拖曳方式修改設計師", icon: "warning" });
          eventDropInf.revert();
          return;
        }
        let orderId = eventDropInf.event.extendedProps.orderId;
        currentOrderId = orderId;
        changeTimeModal.show({orderId, startTime: eventDropInf.event.start, revertCallback: eventDropInf.revert});
      // data.updateVisible = true;
      // data.updateInfo = {
      //   Time: formatDate(eventDropInf.event.start, "yyyy/MM/ddTHH:mm+08:00"),
      //   ChangeTime: formatDate(eventDropInf.event.start, "yyyy/MM/dd HH:mm"),
      //   StartTime: "",
      //   EndTime: "",
      // };
      // if (eventDropInf.newResource) {
      //   data.updateInfo.EmployeeId = eventDropInf.newResource._resource.id;
      //   data.updateInfo.ChangeStylist = eventDropInf.newResource._resource.title;
      // }
      // data.orderId = eventDropInf.event._def.extendedProps.orderId;
      // data.eventInfo = eventDropInf;
    },
      eventClassNames: function (arg) {
        return arg.event.extendedProps.class;
      },
      datesSet: function (info) {
        console.log("Dates set", info);
        // if (!monthMode) {
        if (currentDate !== info.startStr) {
          // console.log("Date change", info);

          currentDate = info.startStr;
          queryData.startDate = $h.formatDate(
            new Date(info.startStr),
            "yyyy/MM/dd"
          );
          queryData.endDate = $h.formatDate(
            new Date(info.endStr),
            "yyyy/MM/dd"
          );
          refreshCal();
        } else {
          return;
        }
        // }
      },
      select: function (info) {
        // console.log("Selected!", info);
        if (isReservable === true) {
          selectedTime.value = info.startStr;
          selectedEmployee.value = {
            EmployeeId: info.resource.id,
            Nickname: info.resource.title,
          };
          reserveVisible.value = true;
        } else if (isReservable === false) {
          Swal.fire({
            title: "強制預約",
            text: "確定是否預約!？",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "確定",
            cancelButtonText: "取消",
          }).then((result) => {
            if (result.value) {
              selectedTime.value = info.startStr;
              // Employee.find(info.resource.id).then((resp) => {
              //   selectedEmployee = resp.payload;
              //   reserveVisible = true;
              // });
            }
          });
        }
      },
      dateClick: function (dateinfo) {
        // alert('Clicked on'+ dateinfo.dateStr)
      },
      dayCellWillUnmount: function (arg) {
        // 如果是顯示模式為 dayGridMonth，設計師欄帶入對應背景色
        if (arg.view.type !== "dayGridMonth") {
          arg.el.style.backgroundColor = arg.resource.extendedProps.color;
        }
      },
      resourceLabelDidMount: function (arg) {
        console.log("resource", arg.resource);
        let viewType = arg.view.type;

        arg.el.style.backgroundColor = arg.resource.extendedProps.color;
        arg.el.addEventListener("click", function (el) {
          console.log(el.target);
          if (viewType === "resourceTimeline") {
            if (el.target.tagName === "SPAN") {
              console.log("One!!!");
              switchStylist(arg.resource);
              calendarApi.value.changeView("resourceTimeGridDay");
            }
          } else {
            if (el.target.id === "day") {
              calendarApi.value.changeView("resourceTimeGridDay");
            } else if (el.target.id === "week") {
              calendarApi.value.changeView("resourceTimeGridWeek");
            } else if (el.target.id === "month") {
              openMonthlyView(arg.resource);
            } else if (el.target.id === "shopping") {
              router.push("/basis/invoicing/material-request");
            } else {
              console.log("All!!!");
              switchStylist(arg.resource);
              calendarApi.value.changeView("resourceTimeline");
            }
          }
        });
      },
      resourceLabelContent(arg) {
        // console.log("Resource", arg);
        let viewType = arg.view.type;
        if (viewType !== "resourceTimeline") {
          return {
            html: `<span class="stylistName">${arg.resource.title}(返回)</span>
              <button class="btn btn-warning" id="day">日預約表</button>
              <button class="btn btn-primary" id="week">周預約表</button>
              <button class="btn btn-secondary" id="month">月預約表</button>
              <button class="btn btn-info" id="shopping">原物料申請</button>
              `,
          };
        }
      },
      resourceLaneDidMount: function (arg) {
        arg.el.style.backgroundColor = arg.resource.extendedProps.color;
      },
      eventClick: function (info) {
        console.log("Event Clicked!", info.event.extendedProps);
        let eventId = info.event.extendedProps.eventId;
        // if (
        //   info.event.extendedProps.eventType !== 0 &&
        //   info.event._def.title !== "休息時段"
        // ) {

        //   return;
        // } else if (info.event._def.title == "休息時段") {
        //   // if (allowForceCreate) {
        //   // }
        //   return;
        // } else {
        let orderId = info.event.extendedProps.orderId;
        currentOrderId = orderId;
        detailModal.show(orderId);
        // }
      },
      eventMouseLeave: function (info) {
        isReservable = true;
      },
    });
    
    onBeforeMount(() => {
      queryData.store = model?.user.storeId;

      Promise.all([
        model?.dispatch("store/find", queryData.store),
        loadStylists(),
      ]).then(([data]) => {
        Object.assign(store, data);
        if (store.BookingStartTime)
          store.BookingStartTimeDP = store.BookingStartTime.split("T")[1];
        if (store.BookingEndTime)
          store.BookingEndTimeDP = store.BookingEndTime.split("T")[1];
        console.log("Store:", data);
        store.Week = data.RecessPeriod?.Durations;
        //console.log(store)
        if (
          store.BookingIntervalTime &&
          store.BookingIntervalTime !== "00:00:00"
        )
          calendarOptions.slotDuration = store.BookingIntervalTime;

        const startTime = $h.formatDate(
            store.BookingStartTime.substring(0, 19),
            "HH:mm"
          ),
          endTime = $h.formatDate(
            store.BookingEndTime.substring(0, 19),
            "HH:mm"
          );
        // Set Bussiness Hours
        calendarOptions.slotMinTime = startTime;
        calendarOptions.slotMaxTime = endTime;
        calendarOptions.businessHours = {
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          startTime: startTime,
          endTime: endTime,
        };
        if (store.RecessPeriod?.Durations.length > 0) {
          store.RecessPeriod?.Durations.filter((e: any, index) => {
            console.log("Event:", e);
            if (e !== null) {
              recessDurationEvents.push({
                title: "休息時段",
                daysOfWeek: [e.DayOfWeek],
                startTime: e.StartTime,
                class: "text-vertical-center",
                icon: "iconsminds-reload",
                endTime: e.EndTime,
                display: "background",
                content: "",
                eventType: 8,
                color: "rgba(90, 90, 90, 0.3)",
              });
            }
          });
          console.log("recessDurationEvents:", recessDurationEvents);
        }
      });
    });

    let refreshCalTimer: number;
    onMounted(() => {
      if (calendarRef.value) {
        console.log("calendarRef:", calendarRef.value);
        // @ts-ignore 取得 calendar API
        calendarApi.value = calendarRef.value.getApi();
      }
      today.value = getTodayDate();
      queryData.startDate = today.value;
      queryData.endDate = today.value + " 23:59:59";
      refreshCal();
      // refreshCalTimer = setInterval(() => refreshCal(), 5000);
    });

    // onBeforeUnmount(() => {
    //   clearInterval(refreshCalTimer);
    // });

    const getTodayDate = () => {
      var fullDate = new Date();
      var yyyy = fullDate.getFullYear();
      var MM =
        fullDate.getMonth() + 1 >= 10
          ? fullDate.getMonth() + 1
          : "0" + (fullDate.getMonth() + 1);
      var dd =
        fullDate.getDate() < 10 ? "0" + fullDate.getDate() : fullDate.getDate();
      var today = yyyy + "/" + MM + "/" + dd;
      return today;
    };

    // 取得店內的設計師名單顯示於行事曆上
    const loadStylists = () => {
      const condition = new Condition();
      condition.and("Employments.StoreId", Operator.Equal, model?.user.storeId);
      condition.and("Employee.ShowInCalendar", Operator.Equal, true);
      model
        ?.dispatch("stylist/query", {
          page: 1,
          pageSize: 30,
          sortings: [new Sorting("Ordinal"), new Sorting("Employee.DataMode")],
          condition: condition,
        })
        .then((resp) => {
          var stylistData = resp.data;
          console.log(stylistData);
          allResources.value = stylistData.map((ele, index) => {
            stylists.push({
              Value: index,
              Id: ele.Id,
              Name: ele.Employee?.Nickname,
            });
            return {
              id: ele.EmployeeId,
              dataId: ele.Id,
              title: ele.Employee?.Person.Nickname,
              color: ele.Employee?.Color,
              hide: false,
              // order: ele.Ordinal,
              index: index,
            };
          });

          console.log("New Resource:", allResources.val);
          calendarOptions.resources = [...allResources.value];
          stylistIds = stylistData.map((ele, index) => {
            return ele.Id;
          });
        });
    };

    // refresh calendar events
    const refreshCal = () => {
      const calendarEle = calendarOptions,
        condition = new Condition();
      // if (queryData.store)
      //   condition.and("StoreId", Operator.Equal, queryData.store);
      if (queryData.startDate)
        condition.and(
          "StartTime",
          Operator.GreaterThanOrEqual,
          queryData.startDate
        );
      if (queryData.endDate)
        condition.and("EndTime", Operator.LessThanOrEqual, queryData.endDate);

      Promise.all([
        model?.dispatch("employeeEvent/query", {
          page: 1,
          pageSize: 100,
          condition: condition,
          sortings: { column: "CreatedTime", order: 1 },
        }),
        setPeformance(),
      ])
        .then(([value]) => {
          let reservations = value.data;

          console.log("reservations", reservations);
          reservations = reservations.map((e, index) => {
            // event 裡面 parse 出來的 Description 為工作日記文字內容並非預約備註
            let event = JSON.parse(e.SerializeContent.Content);
            if (e.Type === 0) {
              return {
                start: event.StartTime,
                // end: event.EndTime,
                title: event.Customer.Name,
                isNew: event.Customer.IsNew,
                isSpecified: e.Order.IsSpecified,
                stylist: event.Name,
                sex: e.Customer.Gender || "",
                icon: genderIcon(e.Customer.Gender),
                content: e.Order.Remark ?? e.Description,
                class: [
                  eventStatus(event.Status),
                  event.EstimatedTime ? "has-estimated" : "",
                ],
                backgroundColor: event.Customer.GradeColor
                  ? event.Customer.GradeColor
                  : "",
                resourceId: event.EmployeeId,
                eventType: event.EventType,
                stylistId: event.Id,
                employeeId: event.EmployeeId,
                customerId: event.CustomerId,
                orderId: e.OrderId,
                editable: event.Status !== 15 ? true : false,
                resourceEditable: event.Status !== 15 ? true : false,
                durationEditable: false,
              };
              /* Official holiday */
            } else {
              return {
                start: e.StartTime,
                end: e.EndTime,
                title: e.Description ?? "休假",
                stylist: "",
                icon: "iconsminds-close",
                // content: e.Description,
                class: "status-notAllowed",
                display: "background",
                style: "",
                resourceId: e.EmployeeId,
                EventType: e.EventType,
                eventId: e.EventId,
                orderId: e.OrderId,
                // editable: e.Status !== 31 ? true : false,
                resourceEditable: e.Status !== 31 ? true : false,
                durationEditable: false,
              };
            }
          });
          calendarApi.value.setOption(
            "events",
            reservations.concat(recessDurationEvents)
          );
        })
        .catch((failure) => {
          CloudFun.send("error", {
            subject: $t?.("message.operate-fail"),
            content: failure,
          });
          dataLoaded.value = false;
        });
    };

    const eventStatus = (status) => {
      let statusClass = "";
      if (status === 0) {
        statusClass = "status-unproved";
      } else if (status === 1) statusClass = "status-approved";
      else if (status === 3) {
        statusClass = "status-inprogress";
      } else if (status === 15) {
        statusClass = "status-checkedOut";
      }
      return statusClass;
    };

    const genderIcon = (gender) => {
      let iconClass = "order-event ";
      if (gender === 0) {
        iconClass += "woman";
      } else if (gender === 1) {
        iconClass += "man";
      } else {
        iconClass += "neutral";
      }
      return iconClass;
    };

    const onInsertCustomer = () => {
      insertCustomerModal.visible = true;
      reserveVisible.value = false;
    };
    const openSelectCustomer = ref<boolean>(true);
    const onAppointment = (isNewCustomer, eventType, customerData?) => {
      console.log("selectedEmployee", selectedEmployee);
      const reserveObject = {
        StartTime: $h.formatDate(
          new Date(selectedTime.value),
          "yyyy/MM/dd HH:mm"
        ),
        Employee: selectedEmployee.value.Nickname,
        EmployeeId: selectedEmployee.value.EmployeeId,
        BirthDate: "",
        Customer: null,
        CustomerId: null,
        Type: eventType || 0,
        IsNew: isNewCustomer || false,
        Partition: "未指定",
      };
      console.log("reserveObject", reserveObject);
      if (isNewCustomer === true) {
        reserveObject.CustomerId = customerData.Id;
        reserveObject.Customer = customerData.Name;
        reserveObject.BirthDate = customerData.BirthDate;
      }

      openSelectCustomer.value = !isNewCustomer;
      appointmentModal.visible = true;
      appointmentModal.data = reserveObject;
      reserveVisible.value = false;
    };

    const openOrderDetail = (event) => {
      currentOrderId = event.Id;
      detailModal.show(currentOrderId);
    };

    const openMonthlyView = (data) => {
      // monthMode.value = true;
      monthlyViewModal.visible = true;
      monthlyViewModal.data = {
        Id: data.id,
        Name: data.title,
      };
    };

    const onLeaveTaken = (isNew, eventType?) => {
      var dataObject = {
        StartTime: $h.formatDate(
          new Date(selectedTime.value),
          "yyyy/MM/dd HH:mm"
        ),
        EndTime: $h.formatDate(
          new Date(selectedTime.value),
          "yyyy/MM/dd HH:mm "
        ),
        Employee: selectedEmployee.value.Nickname,
        EmployeeId: selectedEmployee.value.EmployeeId,
        Type: eventType || 1,
        IsNew: isNew || false,
      };
      leaveTakenModal.visible = true;
      leaveTakenModal.data = dataObject;
      reserveVisible.value = false;
    };

    const dateChanged = (date) => {
      console.log("Date changed:", date);
      // var calendarApi = calendarApi.value;
      const newDate = date;
      calendarApi.value.gotoDate(newDate);
      // queryData.startDate = newDate
      // queryData.endDate = newDate + ' 23:59:59'
      // refreshCal();
    };

    const switchStylist = (value) => {
      // 點擊的 label 等於已選取的 stylist，則重置所有設計師展開狀態
      console.log(value);
      if (value.id === selectedStylist.value) {
        selectedStylist.value = null;
        calendarApi.value.setOption("resources", allResources.value);
      } else {
        var i;
        allResources.value.forEach((e, index) => {
          if (e.id === value.id) {
            console.log("match", e.id);

            selectedStylist.value = value.id;
            i = index;
          }
        });
        queryData.employee = selectedStylist.value;
        calendarApi.value.setOption(
          "resources",
          new Array(allResources.value[i])
        );
      }
      console.log("currentR:", calendarApi.value.getResources());
      console.log("currentR2:", calendarOptions.resources);
      // setPeformance();
    };

    const setPeformance = () => {
      const condition = new Condition();

      if (queryData.store)
        condition.and("StoreId", Operator.Equal, queryData.store);
      if (selectedStylist.value)
        condition.and("EmployeeId", Operator.Equal, selectedStylist.value);
      if (queryData.startDate)
        condition.and(
          "StartTime",
          Operator.GreaterThanOrEqual,
          queryData.startDate
        );
      if (queryData.endDate)
        condition.and("EndTime", Operator.LessThanOrEqual, queryData.endDate);

      model
        ?.dispatch("order/statistics", {
          page: 1,
          pageSize: 100,
          condition: condition,
          sortings: { column: "CreatedTime", order: 1 },
        })
        .then((performance) => {
          storePeformance.customer = $h.commafy(performance.CustomerCount);
          storePeformance.amount = $h.commafy(performance.ServiceAmount);
        });
    };

    const onEmployeePerformance = (type) => {
      console.log("click");
      if (selectedStylist.value) {
        const performanceData = {
          type: type,
          data: [],
          params: {
            startDate: $h.formatDate(
              $h.getFirstDayOfMonth(queryData.startDate),
              "yyyy/MM/dd"
            ),
            endDate: $h.formatDate(
              $h.getLastDayOfMonth(queryData.startDate),
              "yyyy/MM/dd"
            ),
            employeeId: selectedStylist.value,
          },
        };
        employeePerformanceModal.data = performanceData;
        employeePerformanceModal.visible = true;
      }
    };

    return {
      currentDate,
      calendarRef,
      calendarOptions,
      dateChanged,
      appointmentModal,
      detailModal,
      changeTimeModal,
      employeePerformanceModal,
      insertCustomerModal,
      monthlyViewModal,
      storePeformance,
      leaveTakenModal,
      reserveVisible,
      onInsertCustomer,
      onAppointment,
      onLeaveTaken,
      onEmployeePerformance,
      openOrderDetail,
      refreshCal,
      dataLoaded,
      openSelectCustomer,
    };
  },
});
